import Vue from "vue";
import VueRouter from "vue-router";
import Index from "../views/Index.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Index",
    component: Index
  },
  {
    path: "/mvc",
    name: "Index",
    component: Index
  },
  // {
  //   path:'/searchDetail',
  //   name: 'SearchDetail',
  //   component: ()=>import("@views/searchDetail.vue")
  // },
  {
    path: "*",
    redirect: "/"
  }
];
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};
const originalReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err);
};
const router = new VueRouter({
  mode: "history",
  base: process.env.NODE_ENV === "test" ? "/search" : "/",
  routes
});
export default router;
