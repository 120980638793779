import Vue from "vue";
import VueCookies from "vue-cookies";
import VueClipboard from "vue-clipboard2";
import moment from "moment";
import PerfectScrollbar from "vue2-perfect-scrollbar";
import App from "./App.vue";
import 'lib-flexible';
import router from "./router";
import store from "./store";
import "./plugins/element.js";
// import i18n from "./i18n";
import VueI18n from "vue-i18n";
import utils from "./utils/index";
import "./assets/css/icon.scss"
import "./assets/css/reset.css";
import "./assets/css/global.scss";
import "./assets/css/element-variables.scss";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import LyTab from 'ly-tab';

Vue.config.productionTip = false;
// Vue.prototype.$Injector = ShowMoneyInjector;
Vue.prototype.$moment = moment;
Vue.prototype.$utils = utils;
Vue.prototype.$EventBus = new Vue();

Vue.use(VueCookies);
Vue.use(VueClipboard);
Vue.use(PerfectScrollbar);
Vue.use(LyTab)
Vue.use(VueI18n)
const i18n = new VueI18n({
  locale: "zh", // 定义默认语言为中文
  messages: {
    zh: require("./assets/lang/zh.json"),
    en: require("./assets/lang/en.json")
  }
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount("#app");
