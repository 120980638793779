<template>
  <div class="openway">
    <div name="select">
      <keep-alive>
      <el-select v-model="value" :disabled="disabled">
        <el-option
          v-for="item in options"
          :key="item.value"
          :value="item.value"
          >
        </el-option>
      </el-select>
      </keep-alive>
    </div>
    <div name='detail' class="detail" @click="selectItem">{{$t("toDetail")}}</div>
  </div>
</template>

<script>


export default {
  name: "openway",
  props:{
      protocolSource:{
        type:Object,
        default:()=>{}
      }
  },
  data() {
    return {
      options:[],
      value: "",
      showbuzz:process.env.VUE_APP_SHOWBUZZ,
      metabuzz:process.env.VUE_APP_METABUZZ,
      metanote:process.env.VUE_APP_METANOTE,
      disabled:false

      
    };
  },
  created(){
      this.defaultTo()
  },
 
  methods:{
      defaultTo(){
            if(this.protocolSource.parentNodeName=='metanote'){
                    this.value='MetaNote'
                    this.disabled=true
                    this.options.push({value:'MetaNote'})
                }else{
                  this.value='ShowBuzz'
                  this.options.push({value:'ShowBuzz'},{value:'MetaBuzz'})
                  
                }
      },
      selectItem(){
            switch (this.value) {
              case 'ShowBuzz':
                  window.open(`${this.showbuzz}details/${this.protocolSource.txId}`,"_blank")
                break;
              case 'MetaBuzz':
                  window.open(`${this.metabuzz}detail/${this.protocolSource.txId}`,"_blank")
                break;
              case 'MetaNote':
                  window.open(`${this.metanote}detail/${this.protocolSource.txId}`,"_blank")
              break;
              default:
                break;
            }
      },
      
  }
};
</script>
<style lang="scss" scoped>
::v-deep .el-select{
  width: 100px;
  margin-right: 2px;
  .el-input{
    font-size: 12px;
  }
  .el-input__inner{
    height: 24px !important;
    line-height: 24px;
    text-align: center;
    padding-left:10px ;
    
  }
 .el-input .el-input--prefix {
      .el-input__inner{
        font-size: 10px;
      }
  }
  .el-input__icon{
    height: 24px;
    line-height: 24px;
  }
  .el-input .el-input-suffix {
        .el-input__inner{
          height: 24px;
        }      
}
  ::v-deep .el-select-dropdown__wrap.el-scrollbar__wrap.el-select-dropdown__item.selected{
        margin-bottom: 0;
  }
}
.openway{
  // width: 160px;
  margin-right:8px ;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .detail{
    // width: 68px;
    padding:0 4px;
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    cursor: pointer;
    border:1px solid #5f5e5a;
    background-color:#5f5e5a;
    border-radius:2px ;
     color: #eeeeee;
    &:hover{
      color: #3473D9;
    }
  }
  
  
}
@media screen and (max-width: 320px){
  ::v-deep .el-select{
    
  width: 100px;
  
  // margin-right: 2px;
  .el-input{
    font-size: 12px;
  }
  .el-input__inner{
    height: 24px !important;
    padding-left:10px ;
    
  }
 .el-input .el-input--prefix {
      .el-input__inner{
        font-size: 10px;
      }
  }
  .el-input__icon{
    height: 24px;
    line-height: 24px;
  }
  .el-input .el-input-suffix {
        .el-input__inner{
          height: 24px;
        }      
}
  ::v-deep .el-select-dropdown__wrap.el-scrollbar__wrap.el-select-dropdown__item.selected{
        margin-bottom: 0;
  }
 
}
 .openway{
  // width: 160px;
  // margin-right:8px ;
  display: flex;
  justify-content: space-between;
  align-items: center;
  .detail{
    
    height: 20px;
    line-height: 20px;
    font-size: 12px;
    cursor: pointer;
    border:1px solid #5f5e5a;
    background-color:#5f5e5a;
    border-radius:2px ;
     color: #eeeeee;
    &:hover{
      color: #3473D9;
    }
  }

  
}
}
</style>