<template>
  <div class="header_box">
    <div class="logocontent"></div>
    <div class="login_box">
      <Login class="indexlogin" />
      <LangAndAppCenter />
    </div>
  </div>
</template>
<script>
import Login from "@components/Login";
import LangAndAppCenter from "@components/LangAndAppCenter/LangAndAppCenter";
import { mapState } from "vuex";
import { host } from "@utils/baseApi";
export default {
  data: function() {
    return {
      // lang: "cn",
      redirectUri: process.env.VUE_APP_redirectUri,
      toggleLang: true
    };
  },
  components: {
    Login,
    LangAndAppCenter
  },
  computed: {
    ...mapState(["lang"])
  },
  methods: {
    traggleLang() {
      console.log(11111111);
      this.toggleLang = !this.toggleLang;
      this.toggleLang ? this.$store.commit("setLang", "zh") : this.$store.commit("setLang", "en");
      this.lang === "zh" ? (this.$i18n.locale = "zh") : (this.$i18n.locale = "en");
    },
    SetLang() {
      this.$i18n.locale = this.$i18n.locale === "cn" ? "en" : "cn";
      this.lang = this.$i18n.locale === "cn" ? "CN" : "EN";
      localStorage.setItem("lang", this.$i18n.locale);
    },
    authLogin() {
      let httpUrl = host(),
        id = process.env.VUE_APP_httpUrlID;
      // 第三方
      let SMhttp = process.env.VUE_APP_redirectUri + "/{{url}}";
      SMhttp = SMhttp.replace("{{url}}", "userLogin");
      this.$store.state.loginLoding = true;
      window.location.href =
        SMhttp +
        "?response_type=code&client_id=" +
        id +
        "&redirect_uri=" +
        httpUrl +
        "&scope=app&from=" +
        httpUrl;
    },
    loginout() {
      this.$cookies.remove("access_token");
      localStorage.removeItem("access_token");
      this.$store.state.loginLoding = false;
      this.$store.state.userInfo = {};
      this.$store.state.groupGather = {};
      this.$store.state.activeGroup = "";
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep {
  .indexlogin {
    button {
      // position: unset !important;
      background: #ffffff;
      padding: 0, 5px;
      color: #3473d9;
      &:hover {
        background-color: #3473d9;
      }
      &:active {
        background-color: #3473d9;
      }
    }
  }
}
.header_box {
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  padding-top: 10px;
  top: 0;
  width: 100%;
  .logocontent {
    display: flex;
  }
  .link {
    text-decoration: none;
    font-weight: bold;
    font-size: 38px;
    color: #3473d9;
    align-self: center;
  }
  .logo {
    width: 66px;
    height: 66px;
    margin-right: 20px;
  }
}
.login_box {
  display: flex;
  align-items: center;
  .lang {
    // width: 2.7rem;
    padding: 5px;
    border-radius: 10px;
    font-size: 13px;
    background: #fff;
    // border: 1px solid #3473D9;
    color: #3473d9;
    margin-right: 10px;
    // margin-right: 1rem;
    outline: none;
    cursor: pointer;
    &:hover {
      background-color: #3473d9;
      color: #fff;
    }
  }
  .avatar {
    width: 2.7rem;
  }
  .el-avatar {
    width: 2.7rem;
    height: 2.7rem;
    object-fit: cover;
  }
}
.lang-and-appcenter {
  margin-right: 10px;
}

@media screen and (max-width: 768px) {
  .indexlogin {
  }
}
</style>
