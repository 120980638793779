<template>
  <div class="infoContainer">
    <div class="container">
      <div class="info">
        <div class="left">
          <div class="userAvator" @click="toShowbuzzIndex">
            <el-avatar @error="errorHandler">
              <img :src="hasAvator ? userAvator : defaultAva" />
            </el-avatar>
          </div>
          <div class="userinfo">
            <div class="infoTop">
              <div class="nickName" @click="toShowbuzzIndex">{{ item.name }}</div>
            </div>
            <div class="infoBottom">
              <div class="metaId">MetaID:{{ item.metaId.slice(0, 6) }}</div>
              <div class="time">{{ $moment(item.timestamp).format("YYYY-MM-DD HH:mm") }}</div>
            </div>
          </div>
        </div>
        <!--选项卡-->
        <div class="openway"><Openway :protocolSource="protocolSource"></Openway></div>
      </div>
      <div class="content">
        <div :class="[item.content.length > this.contentLength ? 'isactive' : '']" ref="active">
          <div class="contentwrap"><p v-html="$options.filters.intercept(markContent)"></p></div>
        </div>
        <!-- <div class="imgContainer">
                    <div class="smallImg" v-for="(ele,index) in item.attachments" :key="index"> 
                       <el-image  :src="ele | imgSrc" :preview-src-list="imageList" @touchmove.prevent></el-image>
                    </div>
            </div> -->
      </div>
      <div class="txid">
        <div @click="toTxId">{{ $t("tx") }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import Openway from "@components/Openway.vue";
import { browserUri, imageApi } from "@utils/baseApi";
// import marked from 'marked'
var that = {};
export default {
  name: "Info",
  components: { Openway },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      hasAvator: false,

      defaultAva: "https://showjob.oss-cn-hangzhou.aliyuncs.com/index/img_photo_default.png",
      isOpen: false,
      show: false,
      contentLength: 401,
      protocolSource: this.item,
      imageList: [],
      markContent: "",
      imgPath: imageApi()
    };
  },
  computed: {
    userAvator() {
      return `${imageApi()}metafile/avatar/${this.item.metaId}?time=${new Date().getTime()}`;
    }
  },
  filters: {
    imgSrc(metafile) {
      return imageApi() + metafile.replace("://", "/compress/").toLowerCase();
    },
    intercept(val) {
      if (val.length > 400) {
        let values = that.value;
        let data = val.indexOf(values) - 28;
        return `<span>...</span>` + val.slice(data, data + 400) + `<span>...</span>`;
      } else {
        return val;
      }
    }
  },
  created() {
    this.lightHigh();
    this.markContent = this.Contentreplace(this.item.content);
    // this.markContent=marked()
    this.getUserAvator();
    that = this;
  },

  methods: {
    // 文字超链接处理
    Contentreplace(item) {
      // const reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-|%)+)/g;
      // return item
      //   .replace(reg, function (s, value) {
      //     console.log("<a href=" + s + ">" + s + "</a>");
      //     console.log(value);
      //     return "<a href=" + s + ">" + s + "</a>";
      //   })
      //   .replace(/\n|\\n/g, "<br>");
      return this.$utils.getTextLength(item);
    },
    errorHandler() {
      return true;
    },
    previewImg() {
      if (this.item.attachments) {
        [...this.item.attachments].map(elel => {
          return this.imageList.push(`${this.imgPath}metafile/${elel.slice(11)}`);
        });
      }
    },
    getUserAvator() {
      if (this.item.avatarTxId) {
        this.hasAvator = true;
      } else {
        this.hasAvator = false;
      }
    },
    lightHigh() {
      let keyword = new RegExp(this.value, "g");
      this.item.content = this.item.content.trim().replace(keyword, search => {
        return `<span style='color:#3473D9;font-weight:bold '>${search}</span>`;
      });
    },
    toTxId() {
      window.open(browserUri() + `${this.item.txId}`, "_blank");
    },
    toShowbuzzIndex() {
      window.open(
        `${process.env.VUE_APP_SHOWBUZZ}user_index/user_buzz/${this.item.metaId}`,
        "_blank"
      );
    }
  },
  mounted() {
    console.log("xzaawzzxzx", this.item);
    this.previewImg();
  }
};
</script>
<style lang="scss" scoped>
.infoContainer {
  border-bottom: 1px solid #edeff2;
  width: 620px;
  margin-top: 20px;
  margin-left: 30px;
}
.contentwrap {
  white-space: nowrap;
  white-space: pre-line;
  word-wrap: break-word; //中文换行
  word-break: break-all; //英文换行，以字母为依据
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  font-size: 16px;
  text-align: left;
  line-height: 21px;
  p {
    font-size: 16px;
    font-family: PingFangSC-Medium, sans-serif;
  }
}
.container {
  margin: 10px 0;
  .txid {
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;
    margin-left: 5px;
    cursor: pointer;
    color: #a9aaaa;
  }
}
::v-deep .el-image-viewer__mask {
  position: absolute;
  top: 60px;
}
::v-deep .el-image-viewer__wrapper {
  .el-image-viewer__btn.el-image-viewer__close {
    top: 110px;
    right: 60px;
    background-color: #606266;
    color: #fff;
  }
}
.info {
  display: flex;
  justify-content: space-between;
  .userinfo {
    margin-left: 0.222222rem /* 12/54 */;
    display: flex;
    flex-direction: column;
    line-height: 1.5em;
  }
  .left {
    display: flex;
  }
  .userAvator {
    ::v-deep .el-avatar {
      border: 0;
      display: inline-block;
      width: 0.666667rem /* 36/54 */;
      border-radius: 0.666667rem /* 36/54 */;
      height: 0.666667rem /* 36/54 */;
      img {
        object-fit: cover;
        width: 0.666667rem /* 36/54 */;
        height: 0.666667rem /* 36/54 */;
      }
    }
  }
  .infoTop {
    // width: 100%;

    height: 20px;
    font-size: 14px;
    display: flex;
    .nickName {
      font-weight: bold;
      cursor: pointer;
    }
  }
}
.infoBottom {
  display: flex;
  color: #bfc2cc;
  text-align: left;
  .time {
    margin-left: 12px;
  }
}
.content {
  margin-top: 5px;
  p {
    font-size: 14px;
    color: #303133;
    line-height: 21px;
  }
  .isactive {
    padding-left: 5px;
    white-space: nowrap;
    white-space: pre-line;
    word-wrap: break-word; //中文换行
    word-break: break-all; //英文换行，以字母为依据
    overflow: hidden;
    // text-overflow:ellipsis;
    // -o-text-overflow:ellipsis;
    font-size: 20px;
    line-height: 1.5;
    // display: -webkit-box;
    // -webkit-line-clamp: 3;
    // -moz-line-clamp:3;
    // -o-line-clamp:3;
    // line-clamp: 3;
    -webkit-box-orient: vertical;
    -moz-box-orient: vertical;
    -o-box-orient: vertical;
    box-orient: vertical;
  }
}
.imgContainer {
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 0 15px;
  .smallImg {
    margin: 10px 0;
    margin-right: 10px;
    cursor: pointer;

    ::v-deep .el-image {
      width: 80px;
      height: 80px;
      border-radius: 5px;
    }
  }
}
.open {
  font-size: 14px;
  margin-left: 13px;
  color: gray;
  width: 60px;
  height: 20px;
  cursor: pointer;
  margin: auto;
  margin-top: 10px;

  i {
    border: solid gray;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(45deg);
    &:last-child {
      margin-bottom: 2px;
    }
  }
}
.close {
  font-size: 14px;
  color: gray;
  width: 60px;
  height: 20px;
  cursor: pointer;
  margin: auto;
  margin-top: 10px;
  i {
    border: solid gray;
    border-width: 0 1px 1px 0;
    display: inline-block;
    padding: 4px;
    transform: rotate(-135deg);
    &:last-child {
      margin-top: 3px;
    }
  }
}
.comment {
  font-size: 14px;
  margin-left: 50px;
  display: flex;
  justify-content: space-between;
  div {
    text-align: center;
    cursor: pointer;
    &:hover {
      color: #f8c907;
    }
    span {
      font-size: 16px;
      padding-right: 5px;
      text-align: center;
    }
  }
}
.iconfont {
  font-size: 14px;
}

@media screen and (max-width: 768px) {
  .infoContainer {
    border-bottom: 1px solid #edeff2;
    width: 100vw;
    margin-top: 20px;
    margin-left: 0;
  }
  .openway {
    height: 30px;
    margin-top: -2px;
  }
  .info {
    .userAvator {
      margin-left: 12px /* 12/54 */;
      cursor: pointer;
    }
  }
  .infoBottom {
    display: flex;
    color: #bfc2cc;
    flex-direction: column;
    text-align: left;
    .time {
      margin-left: 0;
    }
  }
  .content {
    width: 100vw;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    p {
      margin-top: 5px;
      margin-left: 12px;
      margin-right: 5px;
      font-size: 14px;
      color: #303133;
      line-height: 21px;
    }
  }
  .container {
    margin: 10px 0;
    .txid {
      margin-top: 10px;
      display: flex;
      justify-content: flex-start;
      margin-left: 10px;
      cursor: pointer;
      color: #a9aaaa;
    }
  }
  .contentwrap {
    white-space: nowrap;
    white-space: pre-line;
    word-wrap: break-word; //中文换行
    word-break: break-all; //英文换行，以字母为依据
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    font-size: 14px;
    text-align: left;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    p {
      font-size: 14px;
      margin-right: 12px;
      font-family: PingFangSC-Medium, sans-serif;
    }
  }
  ::v-deep .el-image-viewer__mask {
    position: absolute;
    top: 60px;
  }
  ::v-deep .el-image-viewer__wrapper {
    .el-image-viewer__btn.el-image-viewer__close {
      top: 140px;
      right: 20px;
    }
    .el-image-viewer__btn.el-image-viewer__prev {
      left: 10px;
    }
    .el-image-viewer__btn.el-image-viewer__next {
      right: 10px;
    }
  }
}
@media screen and (max-width: 320px) {
  ::v-deep .el-image-viewer__wrapper {
    .el-image-viewer__btn.el-image-viewer__close {
      top: 80px;
    }
  }
  .time {
    white-space: nowrap;
  }
  .info {
    display: flex;
    justify-content: space-between;
    .userinfo {
      display: flex;
      flex-direction: column;
    }
    .left {
      display: flex;
    }
    .infoTop {
      font-size: 14px;
      display: flex;
      .nickName {
        font-weight: bold;
        cursor: pointer;
      }
    }
  }
}
</style>
