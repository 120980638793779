const utils={
    getTextLength(item) {
        var l = 0;
        var maxLength = 280;
        let text = item;
        for (var i = 0; i < text.length; i++) {
          if (/[\u4e00-\u9fa5]/.test(text[i])) {
            l += 2;
          } else {
            l++;
          }
          if (l > maxLength) {
            // text = text.substr(0, i) + '<span id="" style="color: #617FFF;"  onclick="Alltext()">全文</span>'
            text =
              text.substr(0, i) +
              `....<span style="color: #617FFF;font-size: 1.1rem;"></span>`;
            break;
          }
        }
        if (l > maxLength) {
          const reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-|%)+)/g;
          return text
            .replace(reg, function(s) {
              // console.log(value)
              return (
                '<a href="' +
                s +
                '" target="_blank" onclick="event.stopPropagation()">' +
                s +
                "</a>"
              );
            })
            .replace(/\n|\\n/g, "<br>");
        } else {
          const reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-|%)+)/g;
          return item
            .replace(reg, function(s) {
              // console.log(value)
              return (
                '<a href="' +
                s +
                '" target="_blank" onclick="event.stopPropagation()">' +
                s +
                "</a>"
              );
            })
            .replace(/\n|\\n/g, "<br>");
        }
      }
} 

export default utils;