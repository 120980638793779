<template>
  <div class="login" v-if="AppPlateform">
    <el-dropdown @command="handleCommand">
      <el-button
        @click="authLogin()"
        v-if="!$store.state.userInfo.infoTxId"
        :loading="$store.state.loginLoding && !$store.state.userInfo.infoTxId"
        class="loginbtn"
        type="primary"
        element-loading-spinner="el-icon-loading"
        >{{ $t("login") }}
      </el-button>
      <div class="info" v-else>
        <div class="personAvatar">
          <el-avatar class="avatar" :src="userAvatar" fit="cover"></el-avatar>
        </div>
        <div class="right">
          <span class="name">{{ $store.state.userInfo.name }}</span>
        </div>
      </div>
      <el-dropdown-menu slot="dropdown" v-if="$store.state.userInfo.infoTxId">
        <el-dropdown-item command="goShowmoney">我的钱包</el-dropdown-item>
        <el-dropdown-item command="logout">退出登录</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>
<script>
import { getToken, refreshToken } from "@api/user";
import { host, imageApi } from "@utils/baseApi";
import { initShowMoneyInjector, hexToBase64Img } from "@utils/common";
import { mapState } from "vuex";
export default {
  data() {
    return {
      redirectUri: process.env.VUE_APP_redirectUri,
      avatar: require("@assets/nav_icon_photo@2x.png"),
      AppPlateform: true
    };
  },
  computed: {
    userAvatar() {
      return `${imageApi()}metafile/avatar/${this.$store.state.userInfo.metaId}`;
    },
    isInApp() {
      return window.appMetaIdJs;
    },
    ...mapState(["isAppPlateform"])
  },
  created() {
    this.init();
  },
  mounted() {},
  methods: {
    handleCommand(command) {
      switch (command) {
        case "goShowmoney":
          this.toShowmoney();
          break;
        case "logout":
          this.outLogin();
          break;
        default:
          break;
      }
      // if(command==''){

      // }
    },
    async init() {
      let code = this.$route.query.code;
      if (code) {
        await this.getUserToken(code);
      } else {
        const result = localStorage.getItem("access_token") || "";
        if (result) {
          const data = await refreshToken(JSON.parse(result).refresh_token);
          const token = data.access_token;
          console.log(token);
          if (!token) {
            localStorage.removeItem("access_token");
            this.$cookies.remove("access_token");
            return;
          }
          localStorage.setItem("access_token", JSON.stringify(data));
          this.$cookies.set("access_token", token, data.expires_in);
          this.$store.state.loginLoding = true;
          this._initShowInject();
        }
      }
      if (this.isInApp) {
        this.$store.state.isAppPlateform = true;
        this.AppPlateform = false;
        // window.handleUserLoginData = this.handleUserLoginData;
        // this.getUserLoginData();
      }
      let roomid = localStorage.getItem("roomid");
      setTimeout(() => {
        if (roomid && !this.$store.state.loginLoding && !this.$store.state.userInfo.showId) {
          this.$messageBox
            .confirm("请先登录！", "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning"
            })
            .then(() => {
              this.authLogin();
            })
            .catch(() => {
              localStorage.removeItem("roomid");
              this.$message({
                type: "info",
                message: "已取消登录！"
              });
            });
        }
      }, 1500);
    },
    SetLang() {
      this.$i18n.locale = this.$i18n.locale === "cn" ? "en" : "cn";
      this.lang = this.$i18n.locale === "cn" ? "CN" : "EN";
      localStorage.setItem("lang", this.$i18n.locale);
    },
    toShowmoney() {
      window.open(`${process.env.VUE_APP_redirectUri}`);
    },
    authLogin() {
      let httpUrl = host(),
        id = process.env.VUE_APP_appClientId;
      // 第三方
      let SMhttp = process.env.VUE_APP_redirectUri + "/{{url}}";
      SMhttp = SMhttp.replace("{{url}}", "userLogin");
      this.$store.state.loginLoding = true;
      window.location.href =
        SMhttp +
        "?response_type=code&client_id=" +
        id +
        "&redirect_uri=" +
        httpUrl +
        "&scope=app&from=" +
        httpUrl;
    },
    outLogin() {
      localStorage.removeItem("access_token");
      localStorage.removeItem("chain");
      // this.$store.commit("setAccessToken", null);
      // this.$cookie.remove("access_token");
      window.location.reload();
    },
    _initShowInject() {
      initShowMoneyInjector(
        () => {
          window.handleUserLoginData = this.handleUserLoginData;
          this.getUserLoginData();
        },
        res => {
          if (res.code === 201 || res.code === 202) {
            localStorage.removeItem("access_token");
            this.$cookies.remove("access_token");
            return this.$message({
              message: res.data.message,
              type: "warning"
            });
          } else {
            return this.$message({
              message: res.data.message,
              type: "warning"
            });
          }
        }
      );
    },
    getUserLoginData() {
      const accessToken = this.$cookies.get("access_token") || "";
      if (this.isInApp) {
        const appClientId = process.env.VUE_APP_appClientId;
        const appClientSecret = process.env.VUE_APP_appClientSecret;
        window.appMetaIdJs.getUserInfo(appClientId, appClientSecret, "handleUserLoginData");
      } else if (accessToken) {
        console.log("aaaaaaa", this.$Injector);
        this.$Injector.getUserInfo({
          accessToken,
          callback: this.handleUserLoginData
        });
      }
    },
    async handleUserLoginData(res) {
      if (typeof res === "string") {
        res = JSON.parse(res);
      }
      const accessToken = this.$cookies.get("access_token") || "";
      if (res.code === 200) {
        this.$store.state.loginLoding = false;
        this.$store.state.userInfo = res.data;
        this.$store.state.userInfo.metaId = res.data.showId;
        this.$store.state.userInfo.metaid = res.data.showId.substring(0, 6);
        this.$store.state.userInfo.headUrl = this.$store.state.userInfo.headUrl
          ? hexToBase64Img(this.$store.state.userInfo.headUrl)
          : process.env.VUE_APP_DEFAULT_AVATOR;
        this.$store.state.userInfo.accessToken = accessToken;
        if (window.appMetaIdJs) {
          this.$store.state.appAccessToken = res.appAccessToken;
        }
      } else if (res.code === 202) {
        this.$store.state.loginLoding = false;
        this.metaIdShow = true;
      } else {
        this.$message({
          message: "User information changes please log in again",
          type: "warning"
        });
        localStorage.removeItem("access_token");
        this.$cookies.remove("access_token");
      }
    },
    async getUserToken(code) {
      const chain = this.$route.query.chain || this.$store.state.chain;
      let data = await getToken(code, chain);
      const token = data.access_token;
      this.$store.state.loginLoding = true;
      localStorage.setItem("access_token", JSON.stringify(data));
      this.$cookies.set("access_token", token, data.expires_in);
      console.log(process.env.VUE_APP_APPSuffix);
      this.$router.push("/" + process.env.VUE_APP_APPSuffix);
      this._initShowInject();
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .el-dropdown {
  vertical-align: top;
}
::v-deep .el-dropdown + .el-dropdown {
  margin-left: 15px;
}
::v-deep .el-icon-arrow-down {
  font-size: 12px;
}
.login {
  display: flex;
  justify-content: center;
  align-items: center;
}
.avatar {
  width: 22px;
  height: 22px;
  margin-right: 0px;
}
.loginbtn {
  width: 50px;
  padding: 0 4px;
  height: 18px;

  text-align: center;
  background: #ffffff;
  border-radius: 10px;
  border: none;
  font-size: 13px;
  font-weight: bold;
  color: #3473d9;
  &:hover {
    color: #ffffff;
    background-color: #3473d9;
  }
  &:active {
    background-color: #3473d9;
  }
}
.info {
  display: flex;
  padding-right: 5px;
  .right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
    margin-left: 10px;
  }
}
.name {
  font-size: 13px;
  font-weight: 500;
  font-family: PingFangSC-Medium, sans-serif;
  color: #303133;
}
@media screen and (max-width: 768px) {
  //  .name{display: none}
  .loginbtn {
    width: 60px;
  }
}
@media screen and (max-width: 375px) {
  .loginbtn {
    width: 70px;
  }
}
</style>
