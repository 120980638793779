<template>
  <ly-tab v-model="selectedId" :items="items" :options="options" @change="handleChange"> </ly-tab>
</template>
<script>
export default {
  data() {
    return {
      selectedId: 0,

      options: {
        activeColor: "#3473D9"

        // 可在这里指定labelKey为你数据里文字对应的字段名
      }
    };
  },
  computed: {
    items() {
      return [
        { label: `${this.$t("all")}` }
        // {label: `${this.$t('article')}`,protocolName:['metanote','SimpleMicroblog','showText']},
        // {label: `${this.$t('comment')}`,protocolName:'PayComment'},
      ];
    }
  },
  methods: {
    handleChange(item) {
      this.$emit("handleChange", item);
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .ly-tabbar {
  margin-left: 0;
  border-bottom: 0px solid #eee;
  box-shadow: 0 0px 0px 0px #eee;
  .ly-tab-list {
    padding-top: 5px;
  }

  .ly-tab-item-label {
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
  }
  // .ly-tab-active-bar{
  //     width:0px !important;
  //    }
}
@media screen and (max-width: 768px) {
  ::v-deep .ly-tabbar {
    margin-left: 0;
  }
}
</style>
