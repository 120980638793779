import Vue from "vue";
import {
  Dropdown,
  DropdownMenu,
DropdownItem,
 
  Popover,
  Button,
  Avatar,
  Message,
  Container,
  Input,
  Icon,
  Tabs,
  TabPane,
  Upload,
  MessageBox,
  Dialog,
  Form,
  FormItem,
  Drawer,
  Loading,
  Switch,
  Checkbox,
  checkboxGroup,
  Pagination,
  Select,
  Option,
  Image,
  Radio,
  DatePicker
} from "element-ui";
import 'element-ui/lib/theme-chalk/base.css';
const msgbox = MessageBox;
const message = Message;
const { alert, confirm } = msgbox;

Vue.use(Popover);
Vue.use(Dropdown);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Button);
Vue.use(Avatar);
Vue.use(Container);
Vue.use(Input);
Vue.use(Icon);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Upload);
Vue.use(Dialog);
Vue.use(Form);
Vue.use(FormItem);
Vue.use(Drawer);
Vue.use(Loading);
Vue.use(Switch);
Vue.use(Checkbox);
Vue.use(Pagination);
Vue.use(Select);
Vue.use(Option)
Vue.use(checkboxGroup);
Vue.use(Image);
Vue.use(Radio);
Vue.use(DatePicker)


Vue.prototype.$message = message;
Vue.prototype.$messageBox = msgbox;
Vue.prototype.$alert = alert;
Vue.prototype.$confirm = confirm;
