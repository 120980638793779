import Vue from "vue";
// import ShowMoneyInjector from "showmoney-injector";
import MetaIdJs from "metaidjs";
import CryptoJS from "crypto-js";
import Bsv from "bsv";
import { host } from "./baseApi";

export function hexToBase64(str) {
  if (!str) {
    return "";
  }
  var a = [];
  for (let i = 0, len = str.length; i < len; i += 2) {
    a.push(parseInt(str.substr(i, 2), 16));
  }
  var binary = "";
  var bytes = new Uint8Array(a);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return btoa(binary);
}

export function hexToBase64Img(str) {
  const base = hexToBase64(str);
  const type = "image/jpeg";
  return "data:" + type + ";base64," + base;
}

export function initShowMoneyInjector(onLoaded, onError) {
  const htmlUrl = process.env.VUE_APP_redirectUri;
  const httpUrl = host();
  const id = process.env.VUE_APP_appClientId;
  Vue.prototype.$Injector = new MetaIdJs({
    baseUri: htmlUrl,
    oauthSettings: {
      clientId: id,
      redirectUri: httpUrl
    },
    onLoaded,
    onError
  });
}

/**
 * 消息时间格式化
 * @param time
 */
export function formatTime(time) {
  let moment = Vue.prototype.$moment;
  // 大于昨天
  if (
    moment()
      .add(-1, "days")
      .startOf("day") > time
  ) {
    return moment(time).format("M/D HH:mm");
  }
  // 昨天
  if (moment().startOf("day") > time) {
    return "昨天 " + moment(time).format("HH:mm");
  }
  return moment(time).format("HH:mm");
}

/**
 * 防止网络攻击
 * @param text 文本
 */
export function parseText(text) {
  let HTML = /<\/?.+?>/gi;
  let COOKIE = /document\.cookie/gi;
  let URL = /^\w+[^\s]+(\.[^\s]+){1,}$/gi;
  let HTTP = /http:\/\//gi;
  if (HTML.test(text)) {
    return "无效输入,别耍花样!";
  }
  if (COOKIE.test(text)) {
    return "无效输入,你想干嘛!";
  }
  // 解析网址
  if (URL.test(text)) {
    if (HTTP.test(text)) {
      return `<a onClick="window.open('${text}','_blank')" target="_blank">${text}</a>`;
    }
    return `<a onClick="window.open('http://${text}','_blank')" target="_blank">${text}</a>`;
  }
  return text.replace(/\n/g, "<br />");
}

/**
 *
 * @param {String} name
 * @param {String} author
 * @param {String} version
 */
export function calcBRFCId(name, author = "", version = "") {
  return Bsv.crypto.Hash.sha256(
    Bsv.crypto.Hash.sha256(Buffer.from(name.trim() + author.trim() + version.trim()))
  )
    .reverse()
    .toString("hex")
    .substring(0, 12);
}
/**
 *
 * @param {String} str
 * @param {String} charset
 * @return {Number}
 */
export function sizeof(str, charset) {
  var total = 0,
    charCode,
    i,
    len;
  charset = charset ? charset.toLowerCase() : "";
  if (charset === "utf-16" || charset === "utf16") {
    for (i = 0, len = str.length; i < len; i++) {
      charCode = str.charCodeAt(i);
      if (charCode <= 0xffff) {
        total += 2;
      } else {
        total += 4;
      }
    }
  } else {
    for (i = 0, len = str.length; i < len; i++) {
      charCode = str.charCodeAt(i);
      if (charCode <= 0x007f) {
        total += 1;
      } else if (charCode <= 0x07ff) {
        total += 2;
      } else if (charCode <= 0xffff) {
        total += 3;
      } else {
        total += 4;
      }
    }
  }
  return total;
}

/* 消息加密 */
export function cryptMessage(message, secret_key) {
  let iv = "0000000000000000";
  message = CryptoJS.enc.Utf8.parse(message);
  secret_key = CryptoJS.enc.Utf8.parse(secret_key);
  iv = CryptoJS.enc.Utf8.parse(iv);

  // Encrypt
  var ciphertext = CryptoJS.AES.encrypt(message, secret_key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  let output = Buffer.from(ciphertext.toString(), "base64");
  return output.toString("hex");
}

/* 消息解密 */
export function decryptMessage(message, secret_key) {
  let iv = "0000000000000000";
  iv = CryptoJS.enc.Utf8.parse(iv);
  secret_key = CryptoJS.enc.Utf8.parse(secret_key);
  let buff;
  try {
    buff = new Buffer.from(message, "hex");
  } catch {
    return message;
  }
  let base64data = buff.toString("base64");
  // Decrypt
  var bytes = CryptoJS.AES.decrypt(base64data, secret_key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return bytes.toString(CryptoJS.enc.Utf8);
}

export function calcMD5(message) {
  return CryptoJS.MD5(CryptoJS.enc.Utf8.parse(message)).toString();
}

export function getTextLength(item) {
  var l = 0;
  var maxLength = 280;
  let text = item;
  for (var i = 0; i < text.length; i++) {
    if (/[\u4e00-\u9fa5]/.test(text[i])) {
      l += 2;
    } else {
      l++;
    }
    if (l > maxLength) {
      // text = text.substr(0, i) + '<span id="" style="color: #617FFF;"  onclick="Alltext()">全文</span>'
      text = text.substr(0, i) + '....<span style="color: #617FFF;font-size: 1.1rem;">全文</span>';
      break;
    }
  }
  if (l > maxLength) {
    const reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-|%)+)/g;
    return text
      .replace(reg, function(s) {
        // console.log(value)
        return (
          '<a href="' + s + '" target="_blank" onclick="event.stopPropagation()">' + s + "</a>"
        );
      })
      .replace(/\n|\\n/g, "<br>");
  } else {
    const reg = /(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-|%)+)/g;
    return item
      .replace(reg, function(s) {
        // console.log(value)
        return (
          '<a href="' + s + '" target="_blank" onclick="event.stopPropagation()">' + s + "</a>"
        );
      })
      .replace(/\n|\\n/g, "<br>");
  }
}
