<template>
    <div>
      <el-date-picker
      v-model="value2"
      type="daterange"
      align="right"
      unlink-panels
      :range-separator="until"
      :start-placeholder="start"
      :end-placeholder="end"
      value-format="timestamp"
      :picker-options="pickerOptions"
      @change='datapick'
        >
    </el-date-picker>
    </div>
</template>
<script>
export default {
    name:'dataPicker',
    data(){
        return{
            pickerOptions: {
                disabledDate(time) {
                return time.getTime() <=1590883200000 - 8.64e6
                },
          shortcuts: [{
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
        },
        value2: ''
      };

    },
    methods:{
        datapick(){
            this.$emit('datapick',this.value2)
        }
    },
    computed:{
      until(){
        return `${this.$t('until')}`
      },
      start(){
        return `${this.$t('start')}`
      },
      end(){
        return `${this.$t('end')}`
      }
    }
}
</script>
<style lang="scss" scoped>
::v-deep .el-input__icon.el-range__icon.el-icon-date{
        line-height: 25px!important;
        height: 25px!important;
        
    }

::v-deep .el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner{
    width: 240px;
    height: 25px;
    line-height: 25px;   
}
::v-deep .el-range-separator{
    line-height:  25px!important;
    font-size: 13px!important;
}
::v-deep .el-input__icon.el-range__close-icon.el-icon-circle-close{
        line-height: 20px!important;
        height: 20px!important;      
}
::v-deep .el-range-editor.is-active, .el-range-editor.is-active:hover{
    border-color: #3473D9;
}

@media screen and (max-width: 768px){

::v-deep .el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner{
    width: 216px;
    height: 33px;
    line-height: 33px; 
    margin-top: 5px;  
}
::v-deep .el-date-editor .el-range-input{
  width: 37%;
}
::v-deep .el-date-editor .el-range__icon{
  margin-left: -10px;
}
::v-deep .el-range-separator{
    line-height:  33px!important;
    font-size: 12px!important;
}
::v-deep .el-date-editor .el-range__close-icon{
    width: 0;
}
::v-deep .el-range-editor.el-input__inner{
   padding:0 10px
}

}
@media screen and (max-width: 375px){
  ::v-deep .el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner{
    width: 200px;
    height: 33px;
    line-height:33px; 
    margin-top: 5px;  
}
::v-deep .el-date-editor .el-range-input, .el-date-editor .el-range-separator{
    font-size: 12px;
}
}
</style>