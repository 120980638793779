<template>
    <div class="tips">
        <div><img src="@assets/img_default@2x.png" alt=""></div>
        <div class="tipsSearch" v-if='!isKeyUp'>{{$t("input")}}</div>
        <div class="tipsword" v-if='!info.length && isKeyUp'>{{$t("noResult")}}</div>
    </div>
</template>
<script>
export default {
    name:"tips",
     props:{
         info:{
             type:Array
         },
         isKeyUp:{
             type:Boolean,
             default:false
         }
     },
     watch:{
         isKeyUp:{
             handler(val){
                 console.log("zzz",val)
             },
             immediate:true
         }
     },
     mounted(){
        
     }
    
}
</script>
<style lang="scss" scoped>
.tips{
    width: 100%;
    display: flex;
    align-content: center;
    flex-direction: column;
    img{
        display: inline-block;
        width: 100px;
        height: 100px;
        margin-top: 158px;
    }
    .tipsword {
        font-size: 14px;
         font-family: PingFangSC-Medium, sans-serif;
        color: #BFC2CC;

    }
    .tipsSearch{
         font-size: 14px;
         font-family: PingFangSC-Medium, sans-serif;
        color: #BFC2CC;
    }
   
}
</style>