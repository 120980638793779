<template>
    <div class="pagination">
        <el-pagination
                background
                layout="prev,pager,next"
                :total="total"
                :current-page.sync="currentPage"
                @current-change="handleCurrentChange"
                @prev-click="prePage"
                @next-click="nextPage"
                :page-size.sync='pageSize'
                >
        </el-pagination>
    </div>
</template>
<script>

export default {
    name:"pagination",
    props:{
        total:{
            type:Number,
            default:0
        },
       
    },
    data(){
        return{
            currentPage:1,
            pageSize:15
        }
    },
    created(){
        
    },
    methods:{
        handleCurrentChange(){
           this.$emit("func",this.currentPage)
        },
        prePage(){
            this.$emit("func",this.currentPage--)
        },
        nextPage(){
            this.$emit("func",this.currentPage++)
        }
    },
    
    
    
}
</script>

<style lang="scss" scoped>
.pagination{
    margin-top: 20px;
    
    
}
::v-deep .el-pager li.active{
    cursor: pointer;
    background-color: #5f5e5a!important;
    
}
::v-deep .el-pagination{
    // margin-left:-376px ;
}

@media screen and (max-width: 768px){
    .pagination{
    width: 100vw;
    
    
}
}
</style>