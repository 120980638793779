<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  created() {
    this.init();
    window.pageYOffset = 60;
    document.documentElement.scrollTop = 60;
  },
  methods: {
    init: function() {
      const chain = localStorage.getItem("chain");
      if (chain) {
        this.$store.commit("setChain", chain);
      }

      this.autoChange();
      this.initUserInfo();
    },

    initUserInfo() {
      let userinfo = localStorage.getItem("userInfo");
      if (userinfo) {
        this.$store.state.userInfo = userinfo;
      }
    },
    autoChange() {
      switch (
        navigator.language?.substring(0, 2).toLowerCase() ||
        navigator.browserLanguage?.substring(0, 2).toLowerCase()
      ) {
        case "en":
          this.$i18n.locale = "en";
          this.$store.commit("setLang", this.$i18n.locale);
          break;
        case "zh":
          this.$i18n.locale = "zh";
          this.$store.commit("setLang", this.$i18n.locale);
          break;
        default:
          break;
      }
    }
    // initLanguage() {
    //   let lang = localStorage.getItem("lang");
    //   if (lang) {
    //     this.$i18n.locale = lang;
    //   } else {
    //     let language = navigator.language || navigator.userLanguage;
    //     language = language.substr(0, 2); //截取lang前2位字符
    //     if (language == "zh") {
    //       this.$i18n.locale = "cn";
    //       localStorage.setItem("lang", "cn");
    //     } else {
    //       this.$i18n.locale = "en";
    //       localStorage.setItem("lang", "en");
    //     }
    //   }
    // }
  }
};
</script>
<style lang="scss">
body html {
  width: 100vw;
  height: 100vh;
}
#app {
  // font-family: Avenir, Helvetica, Arial, sans-serif;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  font-family: PingFangSC-Medium, sans-serif;
  text-align: center;
  color: #2c3e50;
  width: 100%;
  // overflow-y: scroll;
  background-size: cover;
  // background-color: #eeeeee;
}
.el-container {
  height: 100%;
  justify-content: center;
}
.menu {
  display: none;
  z-index: 9999;
  position: absolute;
  width: 92px;
  height: 30px;
  background-color: #fff;
  border-radius: 10px;
  line-height: 30px;
  font-size: 10px;
  border: 1px solid #aaa;
  &:after {
    position: absolute;
    left: 5px;
    bottom: -10px;
    content: "";
    width: 0;
    height: 0;
    border-width: 5px;
    border-style: solid;
    border-color: #aaaaaa transparent transparent transparent;
  }
  a {
    color: #404040;
    text-decoration: none;
  }
}
@media screen and (max-width: 1336px) {
  #app {
    width: 100vw;
  }
}
@media screen and (max-width: 768px) {
}
</style>
