<template>
  <div class="lang-and-appcenter">
    <a @click="isShowAppCenter = true" class="el-icon-menu"></a>
    <a @click.stop="setLang">{{ $i18n.locale === "zh" ? "EN" : "CN" }}</a>

    <!-- 应用广场 -->
    <el-dialog
      :visible.sync="isShowAppCenter"
      custom-class="app-center-modal"
      :lock-scroll="true"
      :modal="false"
    >
      <iframe
        :src="`https://www.nftonshow.com/app/index?lang=${$i18n.locale}`"
        frameborder="0"
      ></iframe>
    </el-dialog>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isShowAppCenter: false
    };
  },
  methods: {
    setLang() {
      this.$i18n.locale = this.$i18n.locale === "zh" ? "en" : "zh";
    }
  }
};
</script>
<style lang="scss" scoped>
.lang-and-appcenter {
  display: flex;
  align-items: center;
  margin-left: 10px;
  a {
    padding: 5px;
    border-radius: 10px;
    font-size: 13px;
    background: #fff;
    color: #3473d9;
    margin-right: 10px;
    outline: none;
    cursor: pointer;
    &:hover {
      background: #3473d9;
      color: #fff;
    }
  }
}

@media screen and (max-width: 768px) {
  .lang-and-appcenter {
    margin-left: 5px;
    a {
      margin-right: 0;
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
</style>
