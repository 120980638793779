import qs from "qs";
import { axiosPost } from "@utils/ajax";
import { host, walletApi } from "@utils/baseApi";
import store from "../store/index";

/**
 *
 * @param {String} code 获取授权返回的code
 * @return data Promise
 */
export function getToken(code, chain) {
  let httpUrl = host();
  const id = process.env.VUE_APP_appClientId;
  const secret = process.env.VUE_APP_appClientSecret;
  if (chain) {
    store.commit("setChain", chain);
    httpUrl = httpUrl + "/?chain=" + chain;
  }
  const params = {
    grant_type: "authorization_code",
    // 'client_id': id,
    redirect_uri: httpUrl,
    scope: "app",
    client_id: id,
    client_secret: secret,
    code
  };
  console.log("chain", walletApi());
  return axiosPost(
    `${walletApi()}/oauth2/oauth/token`,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      },
      transformRequest: [
        function(data) {
          return qs.stringify(data);
        }
      ]
    },
    params
  );
}

export function refreshToken(refreshtoken) {
  const id = process.env.VUE_APP_appClientId;
  const secret = process.env.VUE_APP_appClientSecret;
  const params = {
    grant_type: "refresh_token",
    // 'client_id': id,
    client_id: id,
    client_secret: secret,
    refresh_token: refreshtoken
  };
  return axiosPost(
    `${walletApi()}/oauth2/oauth/token`,
    {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8"
      },
      transformRequest: [
        function(data) {
          return qs.stringify(data);
        }
      ]
    },
    params
  );
}
// export const getAllShowAccount = params => {
//   return axiosPost({
//     url: `/showMANDB/api/v1/query/getMetaIDInfoBatch`,
//     data: { data: params }
//   });
// };
//根据关键词搜索
export function getKeyWord(data) {
  return axiosPost(
    `${walletApi()}/aggregation/v2/app/search/getSearchContentBySearchWord`,
    {
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      }
    },
    data
  );
}

//根据昵称搜索
export function getNickName(data) {
  return axiosPost(
    `${walletApi()}/aggregation/v2/app/search/getSearchContentBySearchName`,
    {
      headers: {
        "Content-Type": "application/json;charset=UTF-8"
      }
    },
    data
  );
}
