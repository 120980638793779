import store from "../store/index";

export const walletApi = () => {
  console.log("chain", store.state.chain);
  return store.state.chain === "BSV"
    ? process.env.VUE_APP_WALLET_BSV_API
    : process.env.VUE_APP_WALLET_MVC_API;
};

export const redirectUri = () => {
  return store.state.chain === "BSV"
    ? process.env.VUE_APP_BSV_REDIRECT_URI
    : process.env.VUE_APP_MVC_REDIRECT_URI;
};

export const host = () => {
  return store.state.chain === "BSV" ? process.env.VUE_APP_BSV_HOST : process.env.VUE_APP_MVC_HOST;
};

export const imageApi = () => {
  return store.state.chain === "BSV"
    ? process.env.VUE_APP_BSV_IMG_API
    : process.env.VUE_APP_MVC_IMG_API;
};

export const metaIdTag = () => {
  return store.state.chain === "BSV"
    ? process.env.VUE_APP_BSV_IDtags
    : process.env.VUE_APP_MVC_IDtags;
};

export const browserUri = () => {
  return store.state.chain === "BSV"
    ? process.env.VUE_APP_WHATSONCHAIN
    : process.env.VUE_APP_MVCSCAN;
};
