<template>
  <div class="search">
    <div class="searchDetail">
      <div class="searchHeader">
        <div class="headerLeft">
          <div class="logo" @click="toIndex">
            <img src="@assets/nav_logo_MetaSpotlightBlue.png" />
          </div>
          <div class="demo-input-suffix">
            <el-input v-model.trim="value" ref="searchinput" @keyup.enter.native="search()">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
              <i slot="suffix"
                ><el-button @click="search()"
                  ><span>{{ $t("search") }}</span></el-button
                ></i
              >
            </el-input>
          </div>
        </div>
        <div class="headerRight">
          <div class="userInfoWrap">
            <img :src="userAvatar" alt="" />
            <span>{{ $store.state.userInfo.name }}</span>
          </div>
          <LangAndAppCenter />
          <!-- <Login /> -->
        </div>
      </div>
    </div>
    <!--滚动条-->
    <!-- <loginbtndiv class="tab"><Tab @handleChange="categorySearch"/></loginbtndiv> -->
    <div class="tools">
      <transition name="el-zoom-in-bottom">
        <div class="close" v-show="toolsShow">
          <div class="left">
            <div>
              <el-radio v-model="radio" label="1" fill="#3473D9">{{ $t("byContent") }}</el-radio>
            </div>
            <div>
              <el-radio v-model="radio" label="2" fill="#3473D9">{{ $t("byUsername") }}</el-radio>
            </div>
            <!-- <div><el-radio v-model="radio" label="3" fill='#3473D9'>MetaID搜索</el-radio></div>  -->
          </div>
          <!-- <div class="datapick"><DataPicker @datapick='receive'/></div> -->
          <div class="right" @click="toolsShow = false">
            <i class="el-icon-arrow-up"></i><span>{{ $t("hide") }}</span>
          </div>
        </div>
      </transition>
      <transition name="el-zoom-in-top">
        <div class="open" v-show="!toolsShow">
          <div class="left"></div>
          <div class="right" @click="toolsShow = true">
            <i class="el-icon-connection"></i><span>{{ $t("tools") }}</span>
          </div>
        </div>
      </transition>
    </div>
    <!--主体-->
    <perfect-scrollbar class="scrollbar">
      <div class="searchcontent" v-loading="loading">
        <div class="searchBody" v-if="flag">
          <div class="searchwarp">
            <template v-for="item in info">
              <Info :key="item.timestamp" :item="item" :value="value" />
            </template>
          </div>
        </div>
        <div v-else><Tips :info="info" :isKeyUp="isKeyUp" /></div>
      </div>
      <!--分页-->
      <div class="pagination" v-show="flag">
        <Pagination :total="total" @func="getCurrentPage" ref="pagination" />
      </div>
    </perfect-scrollbar>
  </div>
</template>
<script>
import { getKeyWord, getNickName } from "@api/user.js";
import Info from "@components/info.vue";
import DataPicker from "@components/DataPicker.vue";
import { mapState } from "vuex";
import Pagination from "@components/Pagination.vue";
import Tab from "@components/tab.vue";
import Tips from "@components/tips.vue";
import { getProtocolData, getOwnShowAccount, getNodeName } from "@api/metaid.js";
// import Login from "@components/Login.vue"
import debounce from "@utils/debounce.js";
// import {getAllTxUserData} from "@utils/common"
import LangAndAppCenter from "@components/LangAndAppCenter/LangAndAppCenter";
import { imageApi } from "@utils/baseApi";
export default {
  name: "SearchDetail",
  components: { Info, Pagination, Tab, Tips, DataPicker, LangAndAppCenter },
  data() {
    return {
      toolsShow: true,
      radio: "1",
      flag: true,
      info: [],
      total: 0,
      value: "",
      item: "",
      loading: true,
      protocolItem: [],
      picktimer: [],
      protocolList: [],
      protocolList_protocolname: [],
      isKeyUp: false,
      toggleLang: true
    };
  },
  props: {
    changeview: {
      type: Boolean,
      default: false
    },
    parentKeyWord: {
      type: String,
      default: ""
    }
  },
  computed: {
    ...mapState(["isAppPlateform", "lang"]),
    userAvatar() {
      return `${imageApi()}metafile/avatar/${this.$store.state.userInfo.metaId}`;
    }
  },
  methods: {
    traggleLang() {
      console.log(11111111);
      this.toggleLang = !this.toggleLang;
      this.toggleLang ? this.$store.commit("setLang", "zh") : this.$store.commit("setLang", "en");
      this.lang === "zh" ? (this.$i18n.locale = "zh") : (this.$i18n.locale = "en");
    },
    handlesignmessage(res) {
      console.log(res);
    },
    toIndex() {
      this.$emit("update:changeview", true);
    },
    getCurrentPage(data) {
      this.search(data);
    },
    getRouterDate() {
      this.value = this.$route.params.input;
      this.search();
    },
    loadingmore() {
      setTimeout(() => {
        this.loading = false;
      }, 500);
    },
    categorySearch(val) {
      this.protocolItem = [];
      switch (val.label) {
        case this.$t("all"):
          this.search();
          break;
        case this.$t("article"):
          this.protocolItem = val.protocolName;
          this.search();
          break;
        case this.$t("comment"):
          this.protocolItem.push(val.protocolName);
          this.search();
          break;
        case "转发":
          this.protocolItem.push(val.protocolName);
          this.search();
          break;
        default:
          break;
      }
    },
    search(n = 0) {
      /**
       * content:协议内需要查询的字段
       *
       */

      this.flag = false;
      if (!this.value) {
        return false;
      } else {
        this.loading = true;
      }
      debounce(async () => {
        this.isKeyUp = true;
        let pageNum = n > 1 ? n : 1;

        // let protocolList=this.protocolList
        // let content='data.content'
        // let rePostComment='data.rePostComment'
        if (this.value) {
          if (this.radio == 1) {
            // var keyword=this.value.trim().split(/\s+/g)
            let keyword = this.value.trim();

            const result = await getKeyWord({
              page: pageNum.toString(),
              pageSize: "15",
              searchWord: keyword,
              timestamp: 0
            });
            if (result.code == 0 && result.data.results.items.length) {
              this.info = [...result.data.results.items];
              this.total = result.data.total;
              this.flag = true;
            } else {
              this.info = [];
            }
            console.log("zxzxzxzxzxzxz", result);
            this.loadingmore();

            //     for(let i in keyword){
            //        if(this.picktimer.length==0){
            //         //带协议名
            //            protocolList=[['metanote',{$and:[{'data.content':{$regex:keyword[i]}},{'data.isPrivate':{$ne:1}},{'isNew':true}]}],['SimpleMicroblog',{$and:[{'data.content':{$regex:keyword[i]}},{'data.isPrivate':{$ne:1}},{'isNew':true}]}],['showText',{$and:[{'data.content':{$regex:keyword[i]}},{'data.isPrivate':{$ne:1}},{'isNew':true}]}],['PayComment',{$and:[{'data.content':{$regex:keyword[i]}},{'data.isPrivate':{$ne:1}},{'isNew':true}]}]]
            //               this.protocolItem.map(ele=>{
            //                 protocolList=[]
            //                 if(this.protocolItem.length==1 && ele=='PayComment'){
            //                      protocolList.push([`${ele}`,{$and:[{'data.content':{$regex:keyword[i]}},{'data.isPrivate':{$ne:1}},{'isNew':true}]}])

            //                 }else{
            //                     protocolList=[['metanote',{$and:[{'data.content':{$regex:keyword[i]}},{'data.isPrivate':{$ne:1}},{'isNew':true}]}],['SimpleMicroblog',{$and:[{'data.content':{$regex:keyword[i]}},{'data.isPrivate':{$ne:1}},{'isNew':true}]}],['showText',{$and:[{'data.content':{$regex:keyword[i]}},{'data.isPrivate':{$ne:1}},{'isNew':true}]}]]
            //                 }
            //             })

            //         }
            //         else{
            //             protocolList=[['metanote',{$and:[{'data.content':{$regex:keyword[i]}},{'data.isPrivate':{$ne:1}},{'data.createTime':{$gte:this.picktimer[0]}},{'data.createTime':{$lte:this.picktimer[1]}},{'isNew':true}]}],
            //             ['SimpleMicroblog',{$and:[{'data.content':{$regex:keyword[i]}},{'data.isPrivate':{$ne:1}},{'data.createTime':{$gte:this.picktimer[0]}},{'data.createTime':{$lte:this.picktimer[1]}},{'isNew':true}]}],
            //             ['showText',{$and:[{'data.content':{$regex:keyword[i]}},{'data.isPrivate':{$ne:1}},{'data.createTime':{$gte:this.picktimer[0]}},{'data.createTime':{$lte:this.picktimer[1]}},{'isNew':true}]}],
            //             ['PayComment',{$and:[{'data.content':{$regex:keyword[i]}},{'data.isPrivate':{$ne:1}},{'data.createTime':{$gte:this.picktimer[0]}},{'data.createTime':{$lte:this.picktimer[1]}},{'isNew':true}]}]]

            //                 this.protocolItem.map(ele=>{
            //                 protocolList=[]
            //                 if(this.protocolItem.length==1&&ele=='PayComment'){
            //                     protocolList.push([`${ele}`,{$and:[{'data.content':{$regex:keyword[i]}},{'data.isPrivate':{$ne:1}},{'data.createTime':{$gte:this.picktimer[0]}},{'data.createTime':{$lte:this.picktimer[1]}},{'isNew':true}]}])
            //                 }else{
            //                     protocolList.push([['metanote',{$and:[{'data.content':{$regex:keyword[i]}},{'data.isPrivate':{$ne:1}},{'data.createTime':{$gte:this.picktimer[0]}},{'data.createTime':{$lte:this.picktimer[1]}},{'isNew':true}]}],
            //                     ['SimpleMicroblog',{$and:[{'data.content':{$regex:keyword[i]}},{'data.isPrivate':{$ne:1}},{'data.createTime':{$gte:this.picktimer[0]}},{'data.createTime':{$lte:this.picktimer[1]}},{'isNew':true}]}],
            //                     ['showText',{$and:[{'data.content':{$regex:keyword[i]}},{'data.isPrivate':{$ne:1}},{'data.createTime':{$gte:this.picktimer[0]}},{'data.createTime':{$lte:this.picktimer[1]}},{'isNew':true}]}]])
            //                 }

            //             })
            //         }

            // //带协议名
            //     protocolList.push(['metanote',{content:{$regex:keyword[i]}}],['SimpleMicroblog',{content:{$regex:keyword[i]}}],['ShowText',{content:{$regex:keyword[i]}}],['SimpleArticle',{content:{$regex:keyword[i]}}])
            //     getProtocolData(protocolList,{},pageNum,10).then(res=>res.data.map(item=>{

            //         if(item.encrypt==0){
            //             getOwnShowAccount(item.rootTxId).then(res=>{

            //                 if(res.metaId==item.rootTxId){
            //                     res.metaId=res.metaId.slice(0,6)
            //                     this.userInfo=Object.assign({},this.userInfo,{name:res.name},{avatarTxId:res.avatarTxId},{metaId:res.metaId})
            //                     this.info.push(Object.assign({},item.data,{txId:item.txId},{parentNodeName:item.parentNodeName},{timestamp:item.timestamp},{rootTxId:item.rootTxId},{name:this.userInfo.name},{avatarTxId:this.userInfo.avatarTxId},{metaId:this.userInfo.metaId}) )

            //             }
            //             }
            //        )
            //     }
            //     this.flag=true
            //     this.info=[]
            //     this.total=res.total
            //     this.loading=false
            // }
            // )
            // ).then(()=>{
            //      this.loadingmore()

            // })
            // }
          } else if (this.radio == 2) {
            let nickName = this.value.trim();

            const result = await getNickName({
              page: pageNum.toString(),
              pageSize: "15",
              searchWord: nickName,
              timestamp: 0
            });
            if (result.code == 0) {
              this.info = [...result.data.results.items];

              this.total = result.data.total;
              this.flag = true;
            }
            console.log("zxzxzxzxzxzxz", result);
            this.loadingmore();
            //     let protocolList_nodename="name"
            //     let protocolList_protocolname=this.protocolList_protocolname
            //     // let protocolList_protocolname=[['metanote',{$and:[{'rootTxId':val.metaId},{'data.createTime':{$gte:this.picktimer[0]}},{'data.createTime':{$lte:this.picktimer[1]}}]}]]
            //     // this.protocolItem.map(ele=>{
            //     //     protocolList_protocolname=[]
            //     //     protocolList_protocolname.push(`${ele}`)
            //     // })//{$and:[{'data.content':{$regex:keyword[i]}},{'data.isPrivate':{$ne:1}},{'isNew':true}]} {data:{$regex:this.value}} {$and:[{'data':{$regex:this.value}},{'isNew':true}]}
            //     getNodeName(protocolList_nodename,{$and:[{'data':{$regex:this.value}},{'isNew':true}]}).then((resp)=>resp.data.map( ele=>{
            //          getOwnShowAccount(ele.rootTxId).then((val)=>{
            //          if(this.picktimer.length==0){
            //             protocolList_protocolname=[['metanote',{$and:[{'rootTxId':val.metaId},{'data.isPrivate':{$ne:1}},{'isNew':true}]}],
            //             ['SimpleMicroblog',{$and:[{'rootTxId':val.metaId},{'data.isPrivate':{$ne:1}},{'isNew':true}]}],
            //             ['showText',{$and:[{'rootTxId':val.metaId},{'data.isPrivate':{$ne:1}},{'isNew':true}]}],
            //             ['PayComment',{$and:[{'rootTxId':val.metaId},{'data.isPrivate':{$ne:1}},{'isNew':true}]}]]
            //             this.protocolItem.map(ele=>{
            //             protocolList_protocolname=[]
            //             if(this.protocolItem.length==1&&ele=='PayComment'){
            //                protocolList_protocolname.push([`${ele}`,{$and:[{'rootTxId':val.metaId},{'data.isPrivate':{$ne:1}},{'isNew':true}]}])
            //              }else{
            //                protocolList_protocolname.push([['metanote',{$and:[{'rootTxId':val.metaId},{'data.isPrivate':{$ne:1}},{'isNew':true}]}],
            //                ['SimpleMicroblog',{$and:[{'rootTxId':val.metaId},{'data.isPrivate':{$ne:1}},{'isNew':true}]}],
            //                ['showText',{$and:[{'rootTxId':val.metaId},{'data.isPrivate':{$ne:1}},{'isNew':true}]}]])
            //              }

            //         })
            //         }else{
            //             protocolList_protocolname=[['metanote',{$and:[{rootTxId:val.metaId},{'data.isPrivate':{$ne:1}},{'data.createTime':{$gte:this.picktimer[0]}},{'data.createTime':{$lte:this.picktimer[1]}},{'isNew':true}]}],
            //             ['SimpleMicroblog',{$and:[{rootTxId:val.metaId},{'data.isPrivate':{$ne:1}},{'data.createTime':{$gte:this.picktimer[0]}},{'data.createTime':{$lte:this.picktimer[1]}},{'isNew':true}]}],
            //             ['showText',{$and:[{rootTxId:val.metaId},{'data.isPrivate':{$ne:1}},{'data.createTime':{$gte:this.picktimer[0]}},{'data.createTime':{$lte:this.picktimer[1]}},{'isNew':true}]}],
            //             ['PayComment',{$and:[{rootTxId:val.metaId},{'data.isPrivate':{$ne:1}},{'data.createTime':{$gte:this.picktimer[0]}},{'data.createTime':{$lte:this.picktimer[1]}},{'isNew':true}]}]]
            //             this.protocolItem.map(ele=>{
            //             protocolList_protocolname=[]
            //             if(this.protocolItem.length==1&&ele=='PayComment'){
            //                 protocolList_protocolname.push([`${ele}`,{$and:[{rootTxId:val.metaId},{'data.isPrivate':{$ne:1}},{'data.createTime':{$gte:this.picktimer[0]}},{'data.createTime':{$lte:this.picktimer[1]}},{'isNew':true}]}])
            //             }
            //             else{
            //                  protocolList_protocolname.push([['metanote',{$and:[{rootTxId:val.metaId},{'data.isPrivate':{$ne:1}},{'data.createTime':{$gte:this.picktimer[0]}},{'data.createTime':{$lte:this.picktimer[1]}},{'isNew':true}]}],
            //                  ['SimpleMicroblog',{$and:[{rootTxId:val.metaId},{'data.isPrivate':{$ne:1}},{'data.createTime':{$gte:this.picktimer[0]}},{'data.createTime':{$lte:this.picktimer[1]}},{'isNew':true}]}],
            //                  ['showText',{$and:[{rootTxId:val.metaId},{'data.isPrivate':{$ne:1}},{'data.createTime':{$gte:this.picktimer[0]}},{'data.createTime':{$lte:this.picktimer[1]}},{'isNew':true}]}]])
            //             }
            //             })
            //             }
            //             getProtocolData(protocolList_protocolname,{},pageNum,10).then( res=>{
            //                     if(res.data.length>0){

            //                       res.data.map(item=>{
            //                             if(item.encrypt==0){
            //                                 val.metaId=val.metaId.slice(0,6)
            //                                 this.userInfo=Object.assign({},this.userInfo,{name:val.name},{avatarTxId:val.avatarTxId},{metaId:val.metaId})
            //                                 this.info.push(Object.assign({},item.data,{txId:item.txId},{parentNodeName:item.parentNodeName},{timestamp:item.timestamp},{rootTxId:item.rootTxId},{name:this.userInfo.name},{avatarTxId:this.userInfo.avatarTxId},{metaId:this.userInfo.metaId}) )
            //                                 }
            //                         }
            //                         )
            //                         this.total=res.total
            //                     }

            //              })
            //          }
            //          )
            //                     this.flag=true
            //                     this.info=[]
            //                     this.loading=false
            //     }
            //     ))
            // .then(()=>{
            //     this.loadingmore()

            // })
          } else {
            console.log("@@@");
          }
        }
      }, 400);
    },
    //接收子组件传过来的时间
    receive(data) {
      this.picktimer = [...data];
    }
  },
  created() {
    this.getRouterDate();
    // let accessToken=this.$cookies.get("access_token") || ''
    // // console.log("zzzzzzzzzz",this.$Injector)
    //     this.$Injector.signMessage({
    //       accessToken,
    //       data:{
    //           message:"ss",
    //           path:"0/0"
    //       },
    //       callback:(res)=>{
    //           console.log("zzzzzzz",res);
    //       }
    //   })
  },
  async mounted() {
    this.value = this.parentKeyWord;
    this.search();
    this.$nextTick(() => {
      this.loading = true;
    });
    //   console.log("xzxczxc",this.parentKeyWord)
    //   this.$nextTick(()=>{
    //       this.$on('search',()=>{
    //           console.log("父组件调用了子组件的方法了")
    //       })
    //   })
    //    const res=await getKeyWord({
    //         page:"1",
    //         pageSize:"15",
    //         searchWord:"BSV",
    //         timestamp:0

    //     })
    //     const result=await getNickName({
    //         page:"1",
    //         pageSize:"15",
    //         searchWord:"冯新宇",
    //         timestamp:0
    //     })
    //     console.log("zxasaxzxzxzx",result)
    // this.loadingmore()
    this.loading = false;
    this.$refs.searchinput.focus();
    //  getAllTxUserData('52ff14800998d168d52ba179dc247c0f47370211d3346030cc6d6c560a0ad0bc').then(res=>{
    //      console.log('getAllTxUserData',res);
    //  })
  },
  watch: {
    // parentKeyWord:{
    //    handler(newVal,oldVal){
    //        if(newVal){

    //            this.value=newVal
    //            this.search()
    //        }
    //    },
    //    immediate:true
    // },
    total: {
      handler(newVal, oldVal) {
        if (newVal != oldVal) {
          this.$refs.pagination.currentPage = 1;
        }
      }
    },
    picktimer: {
      handler(newVal) {
        if (this.value && newVal) {
          this.search();
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped>
::v-deep .ps__rail-y {
  display: none;
}
::v-deep .el-radio__input.is-checked + .el-radio__label {
  color: #666;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: #3473d9;
  background: #3473d9;
}
.search {
  margin: 0 auto;
  width: 24.740741rem /* 1336/54 */ /* 1336/54 */;
  display: flex;
  flex-direction: column;
  background-color: #fff;

  .searchDetail {
    position: relative;
    height: 60px;
    display: flex;
    margin-left: 30px;
    justify-content: space-between;
  }
}
.tab {
  position: fixed;
  top: 60px;
  background-color: #fff;
  z-index: 99;
  width: 12.962963rem /* 700/54 */;
}
.tools {
  margin-top: calc(92px * 0.6);
  width: 680px;
  margin-left: 0.555556rem /* 30/54 */;
  box-sizing: border-box;
  position: fixed;
  z-index: 99;
  background-color: #fff;
  box-shadow: 0px 5px 6px -5px #cccccc;
  padding-right: 0.092593rem /* 5/54 */;
  padding-bottom: 5px;
  padding-top: 5px;
  .open {
    padding-top: 0.092593rem /* 5/54 */;
    padding-bottom: 0.092593rem /* 5/54 */;
    display: flex;
    justify-content: space-between;
    .right {
      font-size: 13px;
      cursor: pointer;
      color: #666;
    }
  }
  .close {
    padding-top: 0.092593rem /* 5/54 */;
    display: flex;
    justify-content: space-between;
    .right {
      font-size: 13px;
      cursor: pointer;
      color: #666;
      margin-top: 5px;
    }
    .left {
      display: flex;
      flex-direction: row;
      margin-top: 5px;
      div {
        margin-left: 10px;
      }
    }
  }
}
.searchHeader {
  background-color: #fff;
  z-index: 100;
  position: fixed;
  display: flex;
  width: 24.740741rem /* 1336/54 */;
  height: 60px;
  justify-content: space-between;
  .headerLeft {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .logo {
      width: 2.574074rem /* 139/54 */;
      height: 0.425926rem /* 23/54 */;
      cursor: pointer;
      img {
        width: 2.574074rem /* 139/54 */;
        height: 0.425926rem /* 23/54 */;
        display: inline-block;
      }
    }
    .demo-input-suffix {
      margin-left: 21px;
      ::v-deep .el-input {
        line-height: 1.5em;
        width: 300px;
        :focus {
          border: 1px solid #3473d9;
        }
      }
      ::v-deep .el-input--prefix .el-input__inner {
        border-radius: 32px;
        padding-left: 40px;
        font-size: 14px;
        font-family: PingFangSC-Medium, sans-serif;
        font-weight: 400;
        color: #303133;
        border-radius: 32px;
        height: 36px;
        line-height: 36px;
      }
      ::v-deep .el-input--prefix i {
        font-size: 20px;
        color: #3473d9;
      }
      ::v-deep .el-input__icon {
        line-height: 36px;
        width: 32px;
      }
    }
    ::v-deep .el-button {
      position: relative;
      left: 6px;
      border-radius: 32px;
      height: 100%;
      width: 100px;
      margin-right: 0;
      background-color: #3473d9;
      color: #eeeeee;
      font-size: 13px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .headerRight {
    display: flex;
    align-items: center;
    margin-right: 0.925926rem /* 50/54 */;
    .lang {
      // width: 2.7rem;
      padding: 5px;
      border-radius: 10px;
      font-size: 13px;
      background-color: #3473d9;
      color: #fff;
      margin-right: 10px;
      // margin-right: 1rem;
      outline: none;
      cursor: pointer;
    }
    .userInfoWrap {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-right: 10px;
      span {
        font-size: 14px;
      }
      img {
        width: 25px;
        height: 25px;
        margin-right: 5px;
        border-radius: 50%;
      }
    }
    // .avators{

    //     border: 1px solid #f3f3f3;
    //     border-radius: 50px;
    //     img{
    //         width: 40px;
    //         height: 40px;
    //     }
    // }
    // .userinfo{
    //     display: flex;
    //     flex-direction: column;
    // }
  }
}
.pagination {
  height: 80px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.searchwarp {
  margin-top: calc(90px * 0.7);
}
@media screen and (max-width: 1336px) and (min-width: 768px) {
  .searchHeader {
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .tab {
    position: fixed;
    top: 60px;
    background-color: #fff;
    z-index: 99;
    width: 100%;
  }
  ::v-deep .el-radio__label {
    font-size: 13px;
  }
  .tools {
    width: 100vw;
    margin-left: 0;
    padding-right: 5px;
    .close {
      //  padding-left: 10px;
      .left {
        display: flex;
        flex-direction: row;
        text-align: center;

        div:nth-child(2) {
          margin-top: 2px;
        }
        div:nth-child(3) {
          margin-top: 2px;
        }
      }
      .datapick {
        height: 33px;
      }
      .right {
        margin-top: 8px;
      }
    }
  }

  .search {
    overflow-x: hidden;
    margin: 0 auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    .searchDetail {
      height: 60px;
      display: flex;
      margin-left: 10px;
      justify-content: space-between;
      align-items: center;
    }
  }

  .searchHeader {
    display: flex;
    width: 100vw;
    height: 60px;
    justify-content: space-between;
    .headerLeft {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .demo-input-suffix {
        margin-left: 10px;
        ::v-deep .el-input {
          width: 200px;
          :focus {
            border: 1px solid #3473d9;
          }
        }
        ::v-deep .el-input--prefix .el-input__inner {
          border-radius: 32px;
          padding-left: 40px;
          font-size: 14px;
          font-family: PingFangSC-Medium, sans-serif;
          font-weight: 400;
          color: #303133;
          border-radius: 32px;
          height: 36px;
          line-height: 36px;
        }
        ::v-deep .el-input--prefix i {
          font-size: 20px;
          color: #3473d9;
        }
        ::v-deep .el-input__icon {
          line-height: 36px;
          width: 32px;
        }
      }
      ::v-deep .el-button {
        position: relative;
        left: 6px;
        border-radius: 32px;
        height: 100%;
        width: 70px;
        margin-right: 0;
        font-size: 13px;
      }
    }
    .headerRight {
      display: flex;
      align-items: center;
      margin-right: 15px;
      .userInfoWrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 10px;
        display: none;
        span {
          font-size: 14px;
        }
        img {
          width: 22px;
          height: 22px;
          padding-left: 5px;
          margin-right: 0px;
          border-radius: 50%;
          object-fit: cover;
        }
      }
      // .avators{
      //      display: none;
      //     border: 1px solid #f3f3f3;
      //     border-radius: 50px;
      //     img{
      //         display: none;
      //         width: 40px;
      //         height: 40px;
      //     }
      // }
      // .userinfo{
      //     display: flex;
      //     flex-direction: column;
      // }
    }
  }
  .searchwarp {
    margin-top: calc(100px * 0.7);
  }
}
@media screen and (max-width: 375px) {
  .tools {
    .close {
      padding-left: 0;
    }
  }
}
@media screen and (max-width: 320px) {
  .searchHeader {
    width: 100vw;
    .headerLeft {
      .demo-input-suffix {
        ::v-deep .el-input {
          width: 150px;
        }
      }
      ::v-deep .el-button {
        padding: 12px 12px;
        width: 50px;
      }
    }
  }
}
</style>
