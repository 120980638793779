<template>
  <div class="index">
    <div class="home" v-if="changeview">
      <div class="header"><Header></Header></div>
      <!--搜索-->
      <div class="container">
        <div class="main-title">
          <div class="logo">
            <img src="@assets/logo_MetaSpotlightBlue.png" alt="" />
          </div>
          <div class="title-suffix">
            <div class="">Powered by</div>
            <div class="">{{ chain }}</div>
          </div>
        </div>

        <div class="demo-input-suffix">
          <!-- <el-input v-model="input" :placeholder="placeholder" ref="inputs"  @focus="changeview=false"> -->
          <el-input
            v-model="inputValue"
            :placeholder="placeholder"
            ref="inputs"
            @keyup.enter.native="aa"
          >
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
            <i slot="suffix"
              ><el-button @click="changeview = false"
                ><span>{{ $t("search") }}</span></el-button
              ></i
            >
          </el-input>
        </div>
      </div>
    </div>
    <div class="teleport" v-else>
      <search-detail :changeview.sync="changeview" :parentKeyWord="inputValue" />
      <!-- <search-detail /> -->
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Header from "@components/header";
import SearchDetail from "./searchDetail.vue";
import { host } from "@utils/baseApi";
export default {
  name: "Index",
  computed: {
    ...mapState(["loginLoding", "isAppPlateform", "chain"]),
    placeholder() {
      return `${this.$t("searchTips")}`;
    }
  },
  components: {
    Header,
    SearchDetail
  },
  data() {
    return {
      inputValue: "",
      dialogFormVisible: false,
      joinModalVisible: false,
      publicmetaid: "125bbacd8ab3b802af5901a24386bcaa584e1f9f84aa27c61a0e72f827e43efc",
      changeview: true
    };
  },
  created() {
    this.lang = this.$i18n.locale;
  },
  methods: {
    aa() {
      this.changeview = false;
    },
    closeHandle() {
      this.dialogFormVisible = false;
      this.joinModalVisible = false;
    },
    initGroupState() {
      this.$store.commit("changeGroupState", true);
    },
    createGroup() {
      if (this.loginLoding) {
        return this.$message({
          message: "正在登录，请稍后操作",
          type: "error"
        });
      }
      if (!this.$store.state.userInfo.showId) {
        return this.authLogin();
      }
      this.dialogFormVisible = true;
    },

    authLogin() {
      let httpUrl = host(),
        id = process.env.VUE_APP_httpUrlID;
      // 第三方
      let SMhttp = process.env.VUE_APP_redirectUri + "/{{url}}";
      SMhttp = SMhttp.replace("{{url}}", "userLogin");
      this.$store.state.loginLoding = true;
      window.location.href =
        SMhttp +
        "?response_type=code&client_id=" +
        id +
        "&redirect_uri=" +
        httpUrl +
        "&scope=app&from=" +
        httpUrl;
    }
  }
};
</script>
<style lang="scss" scoped>
.index {
  margin: 0 auto;
  max-width: 1366px;
  height: 100vh;
  .header {
    height: 60px;
    // margin-right: 40px;
  }
  .teleport {
    ::v-deep .ps__rail-y {
      display: none;
      ::v-deep .el-radio__input.is-checked + .el-radio__label {
        color: #666;
      }
      ::v-deep .el-radio__input.is-checked .el-radio__inner {
        border-color: #3473d9;
        background: #3473d9;
      }
    }
  }
}
.main-title {
  display: flex;
  justify-content: center;
  align-items: flex-end;

  .title-suffix {
    margin-left: 16px;
    font-size: 12px;
    color: #666;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

::v-deep {
  .el-dialog {
    margin-top: 20vh;
    max-width: 520px;
    width: 520px;
    text-align: left;
    .el-form-item__label {
      font-size: 14px;
      font-weight: 500;
      color: #333333;
      line-height: 22px;
      margin-bottom: 10px;
    }
    .el-dialog__body {
      padding: 0 30px;
    }
    .el-form-item {
      margin-bottom: 30px;
    }
    .el-dialog__header {
      .el-dialog__title {
        font-size: 22px;
        font-weight: 500;
        color: #333333;
        line-height: 33px;
      }
      padding: 30px 30px 20px;
    }
    .el-dialog__footer {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 90px;
      background: #f6f6f7;
      border-radius: 0px 0px 10px 10px;
      padding: 0 30px 0 0;
      .el-button--text {
        color: #333;
        font-size: 14px;
      }
      .el-button--primary {
        border: none;
        outline: none;
        margin-left: 30px;
        padding: 14px 30px;
        background: #ff7525;
        border-radius: 6px;
      }
    }
  }
}
.container {
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .demo-input-suffix {
    margin-top: 80px;
    ::v-deep .el-input {
      outline: none;
      width: 520px;

      :focus {
        border: 1px solid #3473d9;
      }
    }
    ::v-deep .el-input--prefix .el-input__inner {
      border-radius: 32px;
      height: 44px;
      line-height: 44px;
      padding-left: 40px;
      font-size: 14px;
      font-family: PingFangSC-Medium, sans-serif;
      font-weight: 400;
      color: #303133;
    }
    ::v-deep .el-input--prefix i {
      font-size: 20px;
      color: #3473d9;
    }
    ::v-deep .el-input__icon {
      line-height: 44px;
      width: 32px;
    }
  }
  ::v-deep .el-button {
    position: relative;
    left: 9px;
    border-radius: 32px;
    height: 44px;
    width: 100px;
    margin-right: 0;
    background-color: #3473d9;
    color: #eeeeee;
    font-size: 14px;
  }

  .logo {
    width: 250px;
    height: 41px;
    margin-top: 120px;
    img {
      width: 250px;
      height: 41px;
      display: inline-block;
    }
  }
}
@media screen and (max-width: 768px) {
  .container {
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .demo-input-suffix {
      ::v-deep .el-input {
        width: 8rem;
      }
    }
  }
}
</style>
