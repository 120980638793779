import Vue from "vue";
import Vuex from "vuex";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    userInfo: {}, // 登录账户个人信息
    loginLoding: false,
    Injector: "",
    appAccessToken: "",
    isAppPlateform: false,
    lang: "zh",
    chain: "BSV"
  },
  getters: {},
  mutations: {
    setLang(state, payload) {
      state.lang = payload;
    },
    setChain(state, chain) {
      state.chain = chain;
      localStorage.setItem("chain", chain);
    }
  },
  actions: {},
  modules: {}
});
